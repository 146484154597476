/*=========================
	Srart service CSS
===========================*/
.services .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.services .single-service{
	margin:30px 0;
	position:relative;
	padding-left:50px;
}
.services .single-service i{
	font-size:36px;
	color:#1a76d1;
	position:absolute;
	left:0;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.services .single-service h4{
	text-transform:capitalize;
	margin-bottom:25px;
	color:#2C2D3F;
}
.services .single-service h4{
	color:#2C2D3F;
	font-size:18px;
	font-weight:600;
}
.services .single-service h4:hover{
	color:#1A76D1;
}
.services .single-service p{
	color:#868686;
    font-size: 12px;
}
/*-- Service Details --*/
.services-details-img {
  margin-bottom: 50px;
}

.services-details-img img {
  width: 100%;
  margin-bottom: 30px;
}
.services-details-img h2 {
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 16px;
}
.services-details-img P {
  margin-bottom: 20px;
}
.services-details-img blockquote {
	font-size: 15px;
	color: #4a6f8a;
	background-color: #1a76d1;
	padding: 30px 75px;
	line-height: 26px;
	position: relative;
	margin-bottom: 20px;
	color: #fff;
}
.services-details-img blockquote i {
  position: absolute;
  display: inline-block;
  top: 20px;
  left: 38px;
  font-size: 32px;
}


.service-details-inner h2 {
	font-weight: 600;
	font-size: 30px;
	margin-bottom: 15px;
	border-left: 3px solid #1a76d1;
	padding-left: 15px;
}
.service-details-inner p {
  margin-bottom: 15px;
}
.service-details-inner p:last-child {
  margin-bottom: 0;
}
/*=========================
	End service CSS
===========================*/
/*========================
	Start Clients CSS
==========================*/
.clients{
	background-image:url('/public/assets/images/client-bg.jpg');
	background-size:cover;
	background-position:center;
	padding:100px 0px;
	position:relative;
}

.clients .single-clients img {
	width: 100%;
	cursor: pointer;
	text-align: center;
	float: none;
	padding: 0 35px;
}
.clients .content h2 {
  color: #fff;
  font-size: 28px;
  font-weight: 600;
  line-height: 46px;
  text-transform: uppercase;
}
/*========================
	End Clients CSS
==========================*/

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .services .single-service h4 {
      margin-bottom: 12px;
    }
    .services-details-img h2 {
      font-size: 24px;
    }
    .service-details-inner-left {
      height: 400px;
      margin-bottom: 30px;
    }
    .service-details-inner {
      width: 100%;
      margin: 0;
    }
    .service-details-inner h2 {
      font-size: 24px;
    }
    .services .single-service {
      margin: 15px 0;
    }
    .clients .content h2 {
      font-size: 20px;
    }
}
@media only screen and (max-width: 767px) {
  
    .services .single-service {
      margin: 20px 0;
    }
    .services .single-service h4 {
      margin-bottom: 15px;
    }
    .services .single-service h4 {
      margin-bottom: 10px;
    }
    .services-details-img h2 {
      font-size: 22px;
    }
    .service-details-inner-left {
      height: 300px;
      margin-bottom: 20px;
    }
    .service-details-inner {
      width: 100%;
      margin: 0;
    }
    .services-details-img {
      margin-bottom: 30px;
    }
    .service-details-inner h2 {
      font-size: 24px;
    }
    .clients .content h2 {
      font-size: 20px;
    }
}
@media only screen and (max-width: 450px) {
  
    .services .single-service {
      margin: 20px 0;
    }
    .services .single-service h4 {
      margin-bottom: 15px;
    }
    .services-details-img blockquote {
      padding: 25px;
    }
    .services-details-img blockquote i {
      display: none;
    }
    .clients .content h2 {
      font-size: 18px;
    }
}