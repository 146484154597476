/*==========================
	Start Appointment CSS
============================*/
.appointment{
	background:#fff;
	padding-top:100px;
}
.appointment.single-page {
	background: #fff;
	padding-top: 100px 0;
	padding: 0;
	padding: 100px 0;
}
.appointment .container .row {
    align-items: center;
}
.appointment.single-page .appointment-inner {
	padding: 40px;
	box-shadow: 0px 0px 10px #00000024;
	border-radius: 5px;
}
.appointment.single-page .title h3 {
	font-size: 25px;
	display: block;
	margin-bottom: 10px;
	font-weight:600;
}
.appointment .form{
	margin-top:30px;
}
.appointment .form input {
	width: 100%;
	height: 50px;
	border: 1px solid #eee;
	text-transform: capitalize;
	padding: 0px 18px;
	color: #555;
	font-size: 14px;
	font-weight:400;
	border-radius:0;
	border-radius: 4px;
}
.appointment .form textarea{
	width: 100%;
	height:200px;
    padding: 18px;
	border:1px solid #eee;
	text-transform:capitalize;
	resize:none;
	border-radius: 4px;
}
.appointment .form-group .nice-select{
	width: 100%;
	height: 50px;
	line-height: 50px;
	border: 1px solid #eee;
	text-transform: capitalize;
	padding: 0px 18px;
	color: #999;
	font-size: 14px;
	font-weight:400;
	border-radius: 4px;
	font-weight:400;
}
.appointment .form-group .nice-select::after {
	right: 20px;
	color: #757575;
}
.appointment .form-group .list{
	border-radius: 4px;
}
.appointment .form-group .list li{
	color:#757575;
	border-radius:0;
}
.appointment .form-group .list li.selected{
	color:#757575;
	font-weight:400;
}
.appointment .form-group .list li:hover {
	color:#fff;
	background: #1A76D1;
}
.appointment .appointment-image {
    width: 70% ;
    margin: 0 auto;
}
.appointment.single-page .button .btn{
	width:100%;
}
.appointment .button .btn{
	font-weight:500;
}
.appointment .button .btn:hover{
	color:#fff;
}
.appointment .form p{
	margin-top: 10px;
	color:#868686;
}
.appointment.single-page .work-hour{
	background:#1A76D1;
	padding: 40px;
	box-shadow: 0px 0px 10px #00000024;
	border-radius: 5px;
}
.appointment.single-page .work-hour h3 {
	font-size: 25px;
	display: block;
	font-weight:600;
	margin-bottom: 20px;
	color:#fff;
}
.appointment.single-page .time-sidual{
	margin-top:15px;
}
.appointment.single-page .time-sidual{
	overflow:hidden;
}
.appointment.single-page .time-sidual li {
	display: block;
	color: #fff;
	width: 100%;
	margin-bottom: 10px;
}
.appointment.single-page .time-sidual li span{
	display:inline-block;
	float:right;
}
.appointment.single-page .day-head .time {
	font-weight: 400;
	float: right;
}

/*==========================
	End Appointment CSS
============================*/

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .appointment .appointment-image {
      margin-top: 20px;
    }
    .appointment.single-page {
        padding: 70px 0;
      }
      .appointment.single-page .work-hour {
        margin-top: 30px;
      }
}
@media only screen and (max-width: 767px) {
    .appointment .appointment-image {
      margin-top: 20px;
    }
    .appointment.single-page {
        padding: 50px 0;
      }
      .appointment.single-page .work-hour {
        margin-top: 30px;
      }
}
@media only screen and (max-width: 450px) {
.appointment.single-page .appointment-inner {
    padding: 25px;
  }

}