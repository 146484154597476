.legal-body {
    background-image: url(/public/assets/images/legalBG.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.legal{
    background-image: url(/public/assets/images/legal-service.jpg);
}

