.header{
	background-color:#fff;
	position:relative;
}
.header .navbar-collapse{
	padding:0;
}

.header .header-inner {
	background:#fff;
	z-index:999;
	width:100%;
}
.header .header-inner .inner .row {
    display: flex;
    justify-content: space-between;
}
.get-quote{
	margin-top:12px;
}
.get-quote .btn{
	color:#fff;
}
.header .logo {
	display: flex;
	align-items: center;
	justify-content: center;
}
.header .logo img {
	max-width:70px;
}
.header .navbar {
	background: none;
	box-shadow: none;
	border: none;
	margin: 0;
	height: 0px;
	min-height: 0px;
}
.header .nav li{
	margin-right: 15px;
    float: left;
	position:relative;
}
.header .nav li:last-child{
	margin:0;
}
.header .nav li a {
	color: #2C2D3F;
	font-size: 14px;
	font-weight: 500;
	text-transform: capitalize;
	padding: 25px 12px;
	position: relative;
	display: inline-block;
	position:relative;
}
.header .nav li a::before {
	position: absolute;
	content: "";
	left: 0;
	bottom: 0;
	height: 3px;
	width:0%;
	background: #1A76D1;
	border-radius: 5px 5px 0 0;
	opacity:0;
	visibility:hidden;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.active:before{
	opacity:1;
	visibility:visible;
	width: 100%;
}
.header .nav li:hover a:before{
	opacity:1;
	width: 100%;
	visibility:visible;
}
.header .nav li:hover a{
	color:#1a76d1;
}
.header .nav li a i {
	display: inline-block;
	margin-left: 1px;
	font-size: 13px;
}
/* Middle Header */
.header.style2 .header-inner {
	border-top: 1px solid #eee;
}
.header.style2 .logo {
	margin-top:6px;
}
.header .middle-header {
	background: #fff;
	padding: 20px 0px;
}
.header .widget-main{
	float:right;
}
.header.style2 .get-quote {
	margin-top: 0;
}
.header .single-widget {
	position: relative;
	float: left;
	margin-right: 30px;
	padding-left: 55px;
}
.header .single-widget:last-child{
	margin:0;
}
.header .single-widget .logo{
	margin:0;
	padding:0;
	margin-top: 7px;
}
.header .single-widget i {
	position: absolute;
	left: 0;
	top: 6px;
	height: 40px;
	width: 40px;
	line-height: 40px;
	color: #fff;
	background: #1A76D1;
	border-radius: 4px;
	text-align: center;
	font-size: 15px;
}
.header .single-widget h4 {
	font-size: 15px;
	font-weight: 500;
}
.header .single-widget p {
	margin-bottom: 5px;
	text-transform: capitalize;
}
.header .single-widget.btn{
	margin-left:0;
}
/* Dropdown Menu */
.header .nav li .dropdown {
	background: #fff;
	width: 220px;
	position: absolute;
	left:-20px;
	top: 100%;
	z-index: 999;
	-webkit-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
	box-shadow: 0px 3px 5px #3333334d;
	transform-origin: 0 0 0;
	transform: scaleY(0.2);
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	opacity: 0;
	visibility: hidden;
	top: 74px;
	border-left:3px solid #2889e4;
}
.header .nav li:hover .dropdown{
	opacity:1;
	visibility:visible;
	transform:translateY(0px);
}
.header .nav li .dropdown li{
	float:none;
	margin:0;
	border-bottom:1px dashed #eee;
}
.header .nav li .dropdown li:last-child{
	border:none;
}
.header .nav li .dropdown li a {
	padding: 12px 15px;
	color: #666;
	display: block;
	font-weight: 400;
	text-transform: capitalize;
	background: transparent;
	-webkit-transition:all 0.2s ease;
	-moz-transition:all 0.2s ease;
	transition:all 0.2s ease;
}
.header .nav li .dropdown li a:before{
	display:none;
}
.header .nav li .dropdown li:last-child a{
	border-bottom:0px;
}
.header .nav li .dropdown li:hover a{
	color:#1A76D1;
}
.header .nav li .dropdown li a:hover{
	border-color:transparent;
}
/* Right Bar */
.header.style2 .main-menu{
	display:inline-block;
	float:left;
}
.header .right-bar {
	float: right;
}
.header .right-bar {
	padding-top:20px;
}
.header .right-bar {
	display: inline-block;
}
.header .right-bar a {
	color: #fff;
	height: 35px;
	width: 35px;
	line-height: 35px;
	text-align: center;
	background: #1a76d1;
	border-radius: 4px;
	display: block;
	font-size: 12px;
}
.header .right-bar li a:hover{
	color:#fff;
	background:#27AE60;
}
.header .search-top.active .search i:before{
	content:"\eee1";
	font-size:15px;
}
.header .main-menu {
	float: right;
}
/* Search */
.header .search-form {
	position: absolute;
	right: 0;
	z-index: 9999;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	top: 74px;
	box-shadow: 0px 0px 10px #0000001c;
	border-radius: 4px;
	overflow: hidden;
	transform:scale(0);
}
.header .search-top.active .search-form {
	opacity:1;
	visibility:visible;
	transform:scale(1);
}
.header .search-form input {
	width: 282px;
	height: 50px;
	line-height: 50px;
	padding: 0 70px 0 20px;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	border-radius: 3px;
	border: none;
	background: #fff;
	color: #2C2D3F;
}
.header .search-form button {
	position: absolute;
	right: 0;
	height: 50px;
	top: 0;
	width: 50px;
	background: #1A76D1;
	border: none;
	color: #fff;
	border-radius: 0 4px 4px 0;
	border-left:1px solid transparent;
}
.header .search-form button:hover{
	background:#fff;
	color:#1A76D1;
	border-color:#e6e6e6;
}
/* Header Sticky */
.header.sticky .header-inner{
	position:fixed;
	z-index:999;
	top:0;
	left:0;
	bottom:initial;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
	animation: fadeInDown 0.5s both 0.1s;
	box-shadow:0px 0px 13px #00000054;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	/* Header Sticky */
	.section{
		padding:70px 0px;
	}
	.section-title {
		margin-bottom: 35px;
		padding: 0px 80px;
	}
	.section-title h2 {
		font-size: 25px;
		margin-bottom: 12px;
	}
	.section-title p {
		font-size: 13px;
	}
	.btn {
		padding: 11px 20px;
		font-weight: 400;
		font-size: 13px;
	}
	.breadcrumbs {
		padding: 60px 0;
	}
	.breadcrumbs h2 {
		font-size: 32px;
		font-weight:600;
	}
	.breadcrumbs .bread-list {
		margin-top: 5px;
	}
	.breadcrumbs ul li a {
		font-size: 14px;
		font-weight: 400;
	}
	.pagination {
		margin: 30px 0 0 0;
	}
	#scrollUp {
		bottom: 55px;
	}
}	

@media only screen and (max-width: 767px) {
	.header.sticky .header-bottom{
		position:absolute;
		z-index:999;
		top:initial;
		left:0;
		bottom:initial;
	}
	.header .header-inner {
		width: 100%;
		z-index: 333;
		position: absolute;
		position: relative;
		padding-bottom: 20px;
		background: #fff;
	}
	.slicknav_menu{
		display:block;
	}
	.slicknav_menu {
		display: block;
		background: transparent;
		padding: 0;
	}
	.slicknav_btn {
		background: transparent;
		padding: 0;
		margin-top: -30px;
	}
	.slicknav_menu .slicknav_icon-bar{
		box-shadow:none;
	}
	.slicknav_menu{
		display:block;
	}
	.slicknav_menu {
		display: block;
		background: transparent;
		padding: 0;
	}
	.slicknav_btn {
		background: transparent;
		padding: 0;
		margin-top: 28px;
	}
	.slicknav_menu .slicknav_icon-bar{
		box-shadow:none;
	}
	.slicknav_nav {
		clear: both;
		color: #2C2D3F;
		margin: 0;
		font-size: .875em;
		background: transparent;
	}
	.slicknav_nav li a {
		color: #2C2D3F;
		transition: all 0.4s ease;
		background: none;
		padding: 4px 10px;
	}
	.slicknav_nav .slicknav_item a, .slicknav_nav .slicknav_parent-link a {
		padding: 0 10px;
		background:transparent;
	}
	.slicknav_nav li a i{
		display:none;
		margin:0;
	}
	.slicknav_nav li:hover a{
		color:#1A76D1;
	}
	.slicknav_nav li .dropdown li a{
		color:#2C2D3F;
		background:transparent;
	}
	.slicknav_nav li .dropdown li a:hover{
		color:#1A76D1;
	}
	.slicknav_nav li .slicknav_item{
		background:transparent;
	}
	.slicknav_nav .slicknav_row, .slicknav_nav a {
		padding: 0;
		margin: 0;
		padding: 6px 0;
		font-weight:400;
	}
	.slicknav_menu .slicknav_icon-bar {
		background-color: #1A76D1;
		box-shadow: none;
		text-shadow: none;
	}
	.slicknav_nav .slicknav_arrow{
		color:#2C2D3F;
	}
	.slicknav_nav li:hover .slicknav_arrow{
		color:#1A76D1;
	}
	.slicknav_menu .slicknav_icon-bar {
		display: block;
		width: 25px;
		height: 3px;
		-webkit-border-radius: 1px;
		-moz-border-radius: 1px;
		border-radius: 1px;
		-webkit-box-shadow: 0 1px 0 rgba(0,0,0,.25);
		-moz-box-shadow: 0 1px 0 rgba(0,0,0,.25);
		box-shadow: 0 1px 0 rgba(0,0,0,.25);
	}
	.header .main-menu{
		display:none 
	}
	.header .widget-main {
		float: right;
		display: none;
	}
	.header.style2 .slicknav_btn {
		margin-top: 18px;
	}
	.header .slicknav_nav {
		margin-top: 65px;
	}
	.header.style2 .slicknav_nav {
		margin-top: 60px;
	}
	.header.style2 .header-inner{
		display:none;
	}
	.header.style2 .main-menu{
		display:none;
	}
	.header .header-inner .get-quote{
		margin: 0;
		padding: 0;
	}
	.header .header-inner .get-quote .btn {
		color: #fff;
		display: none;
		margin: 0;
		padding: 0;
	}
	.section{
		padding:50px 0px;
	}
	.section-title {
		margin-bottom: 30px;
		padding: 0px 20px;
	}
	.section-title h2 {
		font-size: 22px;
		margin-bottom: 10px;
	}
	.section-title p {
		font-size: 13px;
	}
	
	.breadcrumbs {
		padding: 60px 0;
	}
	.breadcrumbs h2 {
		font-size: 32px;
		font-weight:600;
	}
	.breadcrumbs .bread-list {
		margin-top:10px;
	}
	.breadcrumbs ul li{
		font-size: 14px;
		font-weight: 400;
	}
	.breadcrumbs ul li a {
		font-size: 14px;
		font-weight: 400;
	}
	.btn {
		padding: 11px 20px;
		font-weight: 400;
		font-size: 13px;
	}
}

@media only screen and (max-width: 450px) {
	.header .logo {
		float: left;
		margin-top: 18px;
	  }
	.header .logo img {
		width: 40px;
	}
	.section{
		padding:50px 0px;
	}
	.section-title {
		margin-bottom: 30px;
		padding: 0px 20px;
	}
	.section-title h2 {
		font-size: 22px;
		margin-bottom: 10px;
	}
	.section-title p {
		font-size: 13px;
	}
	.btn {
		padding: 11px 20px;
		font-weight: 400;
		font-size: 13px;
	}
	.header .top-link {
		float: none;
		text-align: center;
		margin-bottom: 10px;
	}
	.header .top-contact {
		float: none;
		text-align: center;
	}
}