.visa-body {
    background-image: url(/public/assets/images/visaBG.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
.visa{
    background-image: url(/public/assets/images/visa-service.png);
}
.visa-section h2 {
    font-size: 30px;
}
