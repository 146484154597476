body{
    font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size:14px;
	color:#888;
	overflow-x: hidden;
}
html{
	scroll-behavior: smooth;
}
#scrollUp {
	bottom: 15px;
	right: 15px;
	padding: 10px 20px;
	background: #1a76d1;
	color: #fff;
	font-size: 25px;
	width: 45px;
	height: 45px;
	text-align: center;
	line-height: 45px;
	padding: 0;
	border-radius: 3px;
	box-shadow: 0px 0px 10px #00000026;
}
#scrollUp:hover{
	background:#2C2D3F;
}
.color-plate {
	position: fixed;
	display: block;
	z-index: 99998;
	padding: 20px;
	width: 245px;
	background: #fff;
	right: -245px;
	text-align: left;
	top: 30%;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
	-webkit-box-shadow: -3px 0px 25px -2px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: -3px 0px 25px -2px rgba(0, 0, 0, 0.2);
	box-shadow: -3px 0px 25px -2px rgba(0, 0, 0, 0.2);
}
.color-plate.active{
	right:0;
}
.color-plate .color-plate-icon {
	position: absolute;
	left: -48px;
	width: 48px;
	height: 45px;
	line-height: 45px;
	font-size: 21px;
	border-radius: 5px 0 0 5px;
	background: #1A76D1;
	text-align: center;
	color: #fff !important;
	top: 0;
	cursor: pointer;
	box-shadow: -4px 0px 5px #00000036;
}
.color-plate h4 {
	display: block;
	font-size: 15px;
	margin-bottom: 5px;
	font-weight: 500;
}
.color-plate p {
	font-size: 13px;
	margin-bottom: 15px;
	line-height: 20px;
}
.color-plate span {
	width: 42px;
	height: 35px;
	border-radius: 0;
	cursor: pointer;
	display: inline-block;
	margin-right: 3px;
}
.color-plate span:hover{
	cursor:pointer;
}
.color-plate span.color1{
	background:#1A76D1;
}
.color-plate span.color2{
	background:#2196F3;
}
.color-plate span.color3{
	background:#32B87D;
}
.color-plate span.color4{
	background:#FE754A;
}
.color-plate span.color5{
	background:#F82F56;
}
.color-plate span.color6{
	background:#01B2B7;
}
.color-plate span.color7{
	background:#6c5ce7;
}
.color-plate span.color8{
	background:#85BA46;
}
.color-plate span.color9{
	background:#273c75;
}
.color-plate span.color10{
	background:#FD7272;
}
.color-plate span.color11{
	background:#badc58;
}
.color-plate span.color12{
	background:#44ce6f;
}