/*=======================
	Start Fun Facts CSS
=========================*/
.fun-facts{
	position:relative;
}
.fun-facts.section{
	padding:120px 0;
}
.fun-facts{
	background:url('/public/assets/images/fun-bg.png');
	background-size:cover;
	background-repeat:no-repeat;
}

.fun-facts .single-fun i {
	position: absolute;
	left: 0;
	font-size: 62px;
	color: #fff;
	height: 70px;
	width: 70px;
	line-height: 67px;
	font-size: 28px;
	text-align: center;
	padding: 0;
	margin: 0;
	border: 2px solid #fff;
	border-radius: 0px;
	top: 50%;
	margin-top: -35px;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
	border-radius: 50%;
}
.fun-facts .single-fun:hover i{
	color:#1A76D1;
	background:#fff;
	border-color:transparent;
}
.fun-facts .single-fun .content {
	padding-left: 80px;
}
.fun-facts .single-fun span {
	color: #fff;
	font-weight: 600;
	font-size: 30px;
	position: relative;
	display: block;
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s eas;
	display: block;
	margin-bottom: 7px;
}
.fun-facts .single-fun p{
	color:#fff;
	font-size:15px;
}
/*===================
	End Fun Facts
=====================*/

/*===================
	Why choose CSS
=====================*/
.why-choose .choose-left h3{
	font-size:24px;
	font-weight:600;
	color:#2C2D3F;
	position:relative;
	padding-bottom:20px;
	margin-bottom:24px;
}
.why-choose .choose-left h3:before{
	position:absolute;
	content:"";
	left:0;
	bottom:0;
	height:2px;
	width:50px;
	background:#1a76d1;
}
.why-choose .choose-left p{
	margin-bottom:15px;
	color: #2C2D3F;
}
.why-choose .choose-left .list li {
	color: #868686;
	margin-bottom: 12px;
}
.why-choose .choose-left .list li:last-child{
	margin-bottom:0px;
}
.why-choose .choose-left .list li i{
	height:15px;
	width:15px;
	line-height:15px;
	text-align:center;
	background:#1a76d1;
	color:#fff;
	font-size:14px;
	border-radius:100%;
	padding-left:2px;
	margin-right:16px;
}
/* Start Faq CSS */
.why-choose{
	background:transparent;
}
.why-choose .choose-right{
	position:relative;
    cursor: pointer;
    transition: .3s all ease-in;
}
.why-choose .right-con{
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.why-choose .choose-right:hover{
    animation: Rescale infinite 1.5s;
}
.video-image img{
    box-shadow: 0 0 5px #868686;
}

/*=======================
	End Why choose CSS
=========================*/
/*===============================
	Start Call to action CSS
=================================*/
.call-action{
	background-image:url('/public/assets/images/call-bg.jpg');
	background-attachment: fixed;
	background-size:cover;
	background-position:center;
	position:relative;
	background-repeat:no-repeat;
}
.call-action .content{
	text-align:center;
	padding:100px 0;
}
.call-action .content h2{
	color:#fff;
	font-size:24px;
	font-weight:600;
	line-height:46px;
    text-transform: uppercase;
}
.call-action .content p {
	color: #dfdfdf;
	margin: 30px 0px;
	font-size: 15px;
}
.call-action .content .btn{
	background:#fff;
	margin-right:20px;
	font-weight:500;
	border:1px solid #fff;
	color:#1a76d1;
}
.call-action .content .btn:before{
	background:#1A76D1;
}
.call-action .content .btn:hover{
	background:#1A76D1;
	color:#fff;
}
.call-action .content .btn:last-child{
	margin-right:0px;
}
.call-action .content .second {
	border: 1px solid #fff;
	color: #fff;
	background: transparent;
	color: #fff !important;
}
.call-action .content .second:before{
	background:#fff;
}
.call-action .content .second:hover{
	color:#1A76D1;
	border-color:transparent;
	background:#fff;
}
.call-action .content .second i{
	margin-left:10px;
}
/*===============================
	End Call to action CSS
=================================*/

@media only screen and (min-width: 768px) and (max-width: 991px){
    .fun-facts.section{
        padding:70px 0;
    }
    .fun-facts .single-fun {
        margin: 20px 0;
    }
    .why-choose .choose-right {
        height: 350px;
        margin-top: 30px;
    }
    .call-action .content {
        padding: 0;
        padding: 70px 0;
      }
      .call-action .content h2 {
        font-size: 18px;
        line-height: 40px;
      }
}
@media only screen and (max-width: 767px) {

    .fun-facts.section{
        padding:70px 0;
    }
    .fun-facts .single-fun{
        margin:0;
        margin:15px 0;
        position:relative;
    }
    .fun-facts .single-fun {
        margin: 35px 0;
        padding-top: 60px;
        text-align: center;
    }
    .fun-facts .single-fun i {
        left: 50%;
        margin-left: -35px;
        top:0;
        position:absolute;
    }
    .fun-facts .single-fun .content {
        padding:0;
    }
    .why-choose .choose-right {
        height: 350px;
        margin-top: 30px;
    }
    .call-action .content {
        padding: 0;
        padding: 70px 0;
      }
      .call-action .content h2 {
        font-size: 18px;
        line-height: 40px;
      }
}

@media only screen and (max-width: 450px) {


    .fun-facts.section{
        padding:70px 0;
    }
    .fun-facts .single-fun{
        margin:0;
        margin:15px 0;
        position:relative;
    }
    .fun-facts .single-fun {
        margin: 35px 0;
        padding-top: 60px;
        text-align: center;
    }
    .fun-facts .single-fun i {
        left: 50%;
        margin-left: -35px;
        top:0;
        position:absolute;
    }
    .fun-facts .single-fun .content {
        padding:0;
    }
    .why-choose .choose-right {
        height: 350px;
        margin-top: 30px;
    }
    .call-action .content {
        padding: 0;
        padding: 70px 0;
      }
      .call-action .content h2 {
        font-size: 16px;
        line-height: 40px;
      }
}

@keyframes Rescale {
    0%{
        transform: scale(1);
    }
    50%{
        transform: scale(1.05);
    }
}