.accounting-body {
    background-image: url(/public/assets/images/accountingBG.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.breadcrumbs {
	background-image: url('/public/assets/images/fun-bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	padding: 60px 0px;
}
.breadcrumbs .bread-inner{
	z-index:22;
	text-align:center;
}
.breadcrumbs h2{
	color: #fff;
	text-transform:capitalize;
	font-size: 38px;
	font-weight: 700;
	margin: 0;
	padding: 0;
}
.breadcrumbs .bread-list {
	display: inline-block;
	margin-top: 20px;
}
.breadcrumbs ul li{
	display: inline-block;
	position: relative;
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	text-transform: capitalize;
}
.breadcrumbs ul li i {
	margin: 0px 10px;
	font-size: 14px;
	font-weight: 600;
	color:#fff;
}
.breadcrumbs ul li a {
	color: #fff;
	font-size: 15px;
	font-weight: 500;
	text-transform: capitalize;
	margin:0;
	padding:0;
}
.breadcrumbs ul li a:hover{
	color:#2C2D3F;
}
.breadcrumbs ul li.active a {
	color: #fff;
	display: inline-block;
}
.left-con {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

@media only screen and (max-width:991px) {
	.reverse  {
		flex-direction: column-reverse;
	}
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    /* Header Sticky */
    .breadcrumbs {
      padding: 60px 0;
    }
    .breadcrumbs h2 {
      font-size: 32px;
      font-weight: 600;
    }
    .breadcrumbs .bread-list {
      margin-top: 5px;
    }
    .breadcrumbs ul li a {
      font-size: 14px;
      font-weight: 400;
    }
}

@media only screen and (max-width: 767px){
    .breadcrumbs {
        padding: 60px 0;
      }
      .breadcrumbs h2 {
        font-size: 32px;
        font-weight: 600;
      }
      .breadcrumbs .bread-list {
        margin-top: 10px;
      }
      .breadcrumbs ul li {
        font-size: 14px;
        font-weight: 400;
      }
      .breadcrumbs ul li a {
        font-size: 14px;
        font-weight: 400;
      }
}