/*=============================
	Start Schedule CSS
===============================*/
.schedule {
	background: #fff;
	margin: 0;
	padding: 0;
	height: 230px;
}
.schedule .schedule-inner {
	position: relative;
	transform: translateY(-30%);
	z-index:9;
}
.schedule .single-schedule {
	position: relative;
	text-align: left;
	z-index:3;
	border-radius:5px;
	background:#1A76D1;
    cursor: pointer;
	 -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}
.schedule .single-schedule .inner {
	overflow:hidden;
	position: relative;
	padding:20px;
	z-index:2;
}
.schedule .single-schedule:before{
	position: absolute;
    z-index: -1;
    content: '';
    bottom: -10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 80%;
    height: 90%;
    background:#1A76D1;
    opacity: 0;
    filter: blur(10px);
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}
.schedule .single-schedule:hover:before{
	opacity: 0.8;
}
.schedule .single-schedule:hover{
	transform: translateY(-5px);
}
.schedule .single-schedule .icon i{
	position: absolute;
	font-size: 110px;
	color: #fff;
	 -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
	z-index:-1;
	visibility:visible;
	opacity:0.2;
	right: -25px;
	bottom: -30px;
}
.schedule .single-schedule span{
	display:block;
	color:#fff;
}
.schedule .single-schedule h4{
	font-size: 20px;
	font-weight:600;
	display:inline-block;
	text-transform:capitalize;
	color:#fff;
	margin-top:13px;
}
.schedule .single-schedule p{
	color:#fff;
	margin-top:22px;
}
.schedule .single-schedule a {
	color: #fff;
	margin-top: 25px;
	font-weight: 500;
	display: inline-block;
	position: relative;
}
.schedule .single-schedule a:before{
	position:absolute;
	content:"";
	left:0;
	bottom:0;
	height:1px;
	width:0%;
	background:#fff;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.schedule .single-schedule a:hover:before{
	width:100%;
	width:100%;
}
.schedule .single-schedule a i{
	margin-left:10px;
}

.schedule .single-schedule .time-sidual{
	overflow:hidden;
	margin-top:17px;
}
.schedule .single-schedule .time-sidual li {
	display: block;
	color: #fff;
	width: 100%;
	margin-bottom:4px;
}
.schedule .single-schedule .time-sidual li:last-child{
	margin:0;
}
.schedule .single-schedule .time-sidual li span{
	display:inline-block;
	float:right;
}
.schedule .single-schedule .day-head .time {
	font-weight: 400;
	float: right;
}

/*=============================
	End Schedule CSS
===============================*/

/*=============================
	Start Feautes CSS
===============================*/
.Feautes{
	padding-top:0;
}
.Feautes.index2{
	padding-top:100px;
}
.Feautes.testimonial-page{
	padding-top:100px;
}
.Feautes .single-features{
	text-align:center;
	position:relative;
	padding:10px 20px;
}
.Feautes .single-features::before {
	position: absolute;
	content: "";
	right: -72px;
	top: 60px;
	width: 118px;
	border-bottom: 3px dotted #1a76d1;
}
.Feautes .single-features.last::before{
	display:none;
}
.Feautes .single-features .signle-icon{
	position:relative;
}
.Feautes .single-features .signle-icon i{
	font-size:50px;
	color:#1a76d1;
	position:absolute;
	left:50%;
	margin-left:-50px;
	top:0;
	height:100px;
	width:100px;
	line-height:100px;
	text-align:center;
	border:1px solid #dddddd;
	border-radius:100%;
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
.Feautes .single-features:hover .signle-icon i{
	background:#1A76D1;
	color:#fff;
	border-color:transparent;
}
.Feautes .single-features h3{
	padding-top: 128px;
	color:#2C2D3F;
	font-weight:600;
	font-size:21px;
}
.Feautes .single-features p {
	margin-top: 20px;
}
/*=============================
	End Feautes CSS
===============================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .schedule {
        background: #fff;
        margin: 0;
        padding: 0;
        height: auto;
        padding: 70px 0;
        border-bottom:1px solid #eee;
    }
    .schedule .schedule-inner {
        transform: none;
    }
    .schedule .single-schedule.last{
        margin-top:30px;
    }
    .Feautes.index2 {
        padding-top: 70px;
    }
    .Feautes .single-features::before {
        display:none;
    }
    .Feautes .single-features {
        text-align: center;
        position: relative;
        padding: 10px 155px;
        margin: 10px 0;
    }
}
@media only screen and (max-width: 767px) {
    .schedule {
        background: #fff;
        margin: 0;
        padding: 0;
        height: auto;
        padding: 50px 0;
        border-bottom:1px solid #eee;
    }
    .schedule .schedule-inner {
        transform: none;
    }
    .schedule .single-schedule{
        margin:15px 0;
    }
    .Feautes.index2 {
        padding-top: 50px;
    }
    .Feautes .single-features .signle-icon i {
        font-size: 42px;
        left: 50%;
        margin-left: -40px;
        height:80px;
        width: 80px;
        line-height: 80px;
    }
    .Feautes .single-features::before {
        display:none;
    }
    .Feautes .single-features {
        text-align: center;
        position: relative;
        padding:0px;
        margin: 15px 0;
    }
    .Feautes .single-features h3 {
        padding-top: 105px;
        font-size: 20px;
    }
}

@media only screen and (max-width: 450px) {
    .schedule {
        background: #fff;
        margin: 0;
        padding: 0;
        height: auto;
        padding: 50px 0;
        border-bottom:1px solid #eee;
    }
    .schedule .schedule-inner {
        transform: none;
    }
    .schedule .single-schedule{
        margin:15px 0;
    }
    .Feautes .single-features .signle-icon i {
        font-size: 42px;
        left: 50%;
        margin-left: -40px;
        height:80px;
        width: 80px;
        line-height: 80px;
    }
    .Feautes .single-features::before {
        display:none;
    }
    .Feautes .single-features {
        text-align: center;
        position: relative;
        padding:0px;
        margin: 15px 0;
    }
    .Feautes .single-features h3 {
        padding-top: 105px;
        font-size: 20px;
    }
}